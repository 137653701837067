.globalHeaderContainer{
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  .feather{
    margin-top: 1em;
    margin-left: 1em;
    position: relative;
    color: $gold;
    display: none;
    @include mq($mobile){
      display: block;
    }
  }
  .containerHeader{
    height: 6em;
    padding: .5em;
    display: flex;
    background-color: #1d1d1d;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    left: 0;
    width: 100vw;
    transition: .5s;
    z-index: 5;
    @include mq($mobile){
      padding: 0;
      height: 100vh;
      width: 70%;
      left: -100%;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      &.visible{
        top: 0 !important;
        left: 0;
      }
      &.hidden{
        top: 0 !important;
      }
    }
    .feather{
      position: absolute;
      top:.5em;
      right: .5em;
    }
    .logoContainer{
      height: 100%;
      width: 20%;
      img{
        height: 90%;
        border: 1px solid #fff;
        border-radius: 8px;
      }
      @include mq($mobile){
        margin-top: 1em;
        margin-left: 2em;
        height: 10%;
        width: auto;
      }
    }
    .headerOptions{
      color: #fff;
      width: 80%;
      .optionsHeader{
        list-style: none;
        display: flex;
        justify-content: space-around;
        align-items: center;
        font-size: .9em;
        text-transform: uppercase;
        padding: 0;
      }
      @include mq($mobile){
         height: 80%;
         margin-left: 2em;
        .optionsHeader{
          flex-direction: column;
          align-items: flex-start;
          li{
            font-size: 2em;
            text-align: left;
            margin: .5em 0;
          }
        }
      }
    }
    &.visible{
      top: 0;
    }
    &.hidden{
      top: -110%;
    }
  }
}

.footer{
    height: 6.5em;
    background-color: $black;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    img{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 2%;
        height: 50%;
    }
    padding: 0 2em;
    .contFooter{
        display: flex;
        justify-content: center;
        align-items: center;
        p{
            color: #fff;
            margin-bottom: 0;
        }
    }
    .socialNetworks{
      display: none;
    }
    @include mq($mobile){
        flex-direction: column;
        .contFooter{
        }
        img{
            position: relative;
            top: auto;
            transform: none;
            height: 40%;
        }
        .socialNetworks{
          margin-top: 5px;
          display: flex;
          justify-content: space-between;
          width: 50%;
        }
    };
}
