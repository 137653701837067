.containerWho{
  background-image: url('../images/who_are.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  .contentWho{
    margin: 0 auto;
    padding: 9em 0;
    width: 80%;
    @include mq($mobile){
      width: 90%;
      p{ font-size: .7em}
    }
    .titleSection{
      @include mq($mobile){
        font-size: 25px;
      }
    }
  }
}
