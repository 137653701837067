.containerDraft{
  display: block;
  height: 246px;
  padding: 1em;
  background-image: url(../images/bg_beer_menu.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @include mq($mobile){
      height: auto;
  }
  .titleAnimate{
      margin-top: 1em;
      margin-bottom: 1em;
      .titleSection{
          color: #fff;
          @include mq($mobile){
            font-size: 25px;
          }
      }
  }
  .contentDraft{
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 100%;
    left:0;
    z-index: 5;
    transition: .8s;
    &.visible{
      top: 0;
    }
    .feather{
      position: absolute;
      top: 2%;
      right: 2%;
      color: $gold;
      z-index: 6;
    }
    .sectionDraft{
      height: 60%;
      width: 100%;
      background-color: $black;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      position: relative;
      .lineDraft{
        width: 95%;
        height: 50%;
        position: absolute;
        left: 2.5%;
        bottom: 1em;
      }
      .sliderDraft{
        width: 80%;
        height: 73%;
        margin: 0 auto;
        .slick-slider{
          height: 100%;
          .slick-list{
            height: 100%;
            .slick-track{
              height: 100%;
              .slick-slide{
                height: 100%;
                > div{
                  height: 100%;
                  .maderal{
                    height: 100%;
                    display: flex !important;
                    justify-content: center;
                    align-items: flex-end;
                    &:focus{
                      outline: none;
                    }
                    .imgMaderal{
                      height: 80%;
                      width: auto;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .glassServed,
      .glassServedLerma{
        height: 20%;
        position: relative;
        transform:translateX(20%);
        transition: .35s;
      }
    }
    .sectionDescription{
      height: 40%;
      width: 100%;
      background-image: url('../images/background_draft.jpg');
      background-size: cover;
      background-position: center bottom;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .infoBeer{
        width: 80%;
        height: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        .logoBeer{
          img{
            width: auto;
            height: auto;
            max-width: 90%;
            max-height: 90%;
          }
          width: 20%;
          text-align: right;
        }
        .descriptionBeer{
          width: 90%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .titleDescription{
            display: flex;
            justify-content: center;
            align-items: flex-end;
            h1{
              margin:0;
              font-size: 1.8em;
              &.styleBeer{
                margin-left: .8em;
                margin-bottom: .05em;
                font-size: 1.5em;
              }
            }
          }
          .detailsBeer{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            margin-top: 1em;
            p{
              font-size: 1.5em;
              margin: 0;
            }
            .rankingBeer{
              padding: 0;
              margin: 0;
              opacity: 0;
              .circleRank{
                display: inline-block;
                list-style: none;
                height: 1.5em;
                width: 1.5em;
                background-color: #c1c1c1;
                border-radius: 50%;
                margin:.5em;
                overflow: hidden;
                .fillCircle{
                  height: 100%;
                }
              }
            }
          }
        }
        .titleInfo{
          height: 10%;
          width: 100%;
          background-color: $gold;
          display: flex;
          align-items: center;
          justify-content: center;
          h1{
            text-transform: uppercase;
            text-align: center;
            font-size: 1.5em;
            font-weight: 400;
            letter-spacing: .5em;
          }
        }
        @include mq($mobile){
          width: 90%;
          flex-wrap: wrap;
          .descriptionBeer{
            margin-left: .5em;
            .titleDescription{
              flex-wrap: wrap;
              h1{
                font-size: .9em;
                &.styleBeer{
                  font-size: .8em;
                }
              }
            }
            .detailsBeer{
              p{
                font-size: 1.2em;
              }
            }
          }
        }
      }
    }
  }
    .contentDrafts{
        height: 100%;
        border: 1px solid $gold;
        .containerTriggersDraft{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            .titleInfo,
            .titleInfoLerma{
                width: 30%;
                max-width: 300px;
                background-color: $gold;
                padding: 1em;
                margin: 0 3em;
                cursor: pointer;
                h1{
                    font-size: 1em;
                }
                @include mq($mobile){
                    width: 90%;
                    margin-bottom: 1em;
                }
            }
        }
    }

  // .titleInfo{
  //
  // }
  // .titleInfo,
  // .titleInfoLerma{
  //   height: 3em;
  //   width: 100%;
  //   background-color: $gold;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   flex-direction: column;
  //   cursor: pointer;
  //   h1{
  //     text-transform: uppercase;
  //     text-align: center;
  //     font-size: 1.5em;
  //     font-weight: 400;
  //     letter-spacing: .5em;
  //   }
  //   position: relative;
  //   @include mq($mobile){
  //     h1{ font-size: .9em;}
  //   }
  // }
}
