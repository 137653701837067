.containerDrinks{
  height: 200vh;
  background-image: url('../images/beers.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  @include mq($mobile){
    height: auto;
  }
  .contentBeers{
    height: 50%;
    display: flex;
    align-items: center;
    position: relative;
    @include mq($mobile){
      height: 100vh;
    }
    .imgLogo{
      height: 60%;
      position: absolute;
      top:20%;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
      @include mq($mobile){
        top: 30%;
        height: 30%;
      }
    }
  }
  .imgDivide{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    height: 15%;
  }
}
