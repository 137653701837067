.containerBranches{
  display: block;
  .contentBranches{
    display: flex;
    justify-content: center;
    height: 100%;
    .imgBranches{
      width: 50%;
      height: 100%;
      background-image: url('../images/fiebre.jpg');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top left;
    }
    .infoBranches{
        background-image: url('../images/bg_place.png');
        background-size: cover;
        background-repeat: no-repeat;
        width: 50%;
        height: 100%;
        color: $white;
      .titleAnimate{
        margin-top: 5em;
        @include mq($mobile){
            margin-top: 2em;
        }
      }
      .branch{
          margin-top: 2em;
        .nameBranch{font-weight: bold}
        p{
          font-size: 1.5em;
          margin: 0;
          font-weight: 500;
          @include mq($mobile){
              font-size:1em;
          }
        }
        button{
          padding: 1em 2em;
          margin: 1em 0;
          width: 30%;
          border: none;
          font-size: 1.2em;
          text-align: center;
          font-family: 'Cinzel', serif;
          font-weight: 600;
        }
      }
    }
  }
  @include mq($mobile){
    height: auto;
    .contentBranches{
      background-image: url('../images/fiebre.jpg');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top left;
      position: relative;
      .imgBranches{
        display: none;
      }
      .maskBranches{
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background-color: #fff;
        opacity: .7;
        z-index: 1;
      }
      .infoBranches{
        width: 100%;
        z-index: 2;
        padding-bottom: 2em;
        .branch{
          button{
            width: 60%;
            border-radius: 8px;
          }
        }
      }
    }
  }
}
