@import "variables.scss";

.white {
  color: $white;
}
.gold {
  color: $gold;
}
.brown{
  color: $brown;
}
.brownD{
  color: $brownD;
}
.black{
  color: $black;
}
.bg_white {
  background-color: $white;
}
.bg_gold {
  background-color: $gold;
}
.bg_black{
  background-color: $black;
}
.bg_brown{
  background-color: $brown;
}
.bg_brownD{
  background-color: $brownD;
}
.pl1 {
  padding-left: 1em;
}
.pl2 {
  padding-left: 2em;
}
.pl3 {
  padding-left: 3em;
}
.pl4 {
  padding-left: 4em;
}
.pl5 {
  padding-left: 5em;
}
.pl6 {
  padding-left: 6em;
}
.pr1 {
  padding-right: 1em;
}
.pr2 {
  padding-right: 2em;
}
.pr3 {
  padding-right: 3em;
}
.pr4 {
  padding-right: 4em;
}
.pr5 {
  padding-right: 5em;
}
.pr6 {
  padding-right: 6em;
}
.pt1 {
  padding-top: 1em;
}
.pt2 {
  padding-top: 2em;
}
.pt3 {
  padding-top: 3em;
}
.pt4 {
  padding-top: 4em;
}
.pt5 {
  padding-top: 5em;
}
.pt6 {
  padding-top: 6em;
}
.pb1 {
  padding-bottom: 1em;
}
.pb2 {
  padding-bottom: 2em;
}
.pb3 {
  padding-bottom: 3em;
}
.pb4 {
  padding-bottom: 4em;
}
.pb5 {
  padding-bottom: 5em;
}
.pb6 {
  padding-bottom: 6em;
}
.ml1 {
  margin-left: 1em;
}
.ml2 {
  margin-left: 2em;
}
.ml3 {
  margin-left: 3em;
}
.ml4 {
  margin-left: 4em;
}
.ml5 {
  margin-left: 5em;
}
.ml6 {
  margin-left: 6em;
}
.mr1 {
  margin-right: 1em;
}
.mr2 {
  margin-right: 2em;
}
.mr3 {
  margin-right: 3em;
}
.mr4 {
  margin-right: 4em;
}
.mr5 {
  margin-right: 5em;
}
.mr6 {
  margin-right: 6em;
}
.mt1 {
  margin-top: 1em;
}
.mt2 {
  margin-top: 2em;
}
.mt3 {
  margin-top: 3em;
}
.mt4 {
  margin-top: 4em;
}
.mt5 {
  margin-top: 5em;
}
.mt6 {
  margin-top: 6em;
}
.mb1 {
  margin-bottom: 1em;
}
.mb2 {
  margin-bottom: 2em;
}
.mb3 {
  margin-bottom: 3em;
}
.mb4 {
  margin-bottom: 4em;
}
.mb5 {
  margin-bottom: 5em;
}
.mb6 {
  margin-bottom: 6em;
}
.tal {
  text-align: left;
}
.tac {
  text-align: center;
}
.tar {
  text-align: right;
}

.App{
  overflow-x: hidden;
}
.cinzel{
  font-family: 'Cinzel', serif !important;
}

.allView{
  position: relative;
  height: 100vh;
  width: 100vw;
  display: inline-block;
  &.parallax{
    background-attachment: fixed;
  }
}
.titleSection{
  margin: 1em 0;
  display: inline-block;
  text-align: center;
}
.subtitle{
  margin: 1em 0;
  color: $gold;
}
.titleAnimate{
  width: fit-content;
  margin: 0 auto;
  position: relative;
  padding: .5em 1em;
  span{
    &.white{
      &::before{background-color: #fff !important}
      &::after{background-color: #fff !important}
    }
    &.first{
      &::before{
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        width: 2px;
        height: 0;
        background-color: $gold;
        transition: 1s;
      }
      &::after{
        content: '';
        position: absolute;
        top: .0;
        left: 50%;
        width: 0;
        height: 2px;
        background-color: $gold;
        transform: rotate(90deg);
        transform-origin: top left;
        transition: 1s;
      }
    }
    &.last{
      &::before{
        content: '';
        position: absolute;
        bottom: 0;
        right: 50%;
        width: 2px;
        height: 0;
        background-color: $gold;
        transition: 1s;
      }
      &::after{
        content: '';
        position: absolute;
        bottom: 0;
        right: 50%;
        width: 15%;
        height: 2px;
        background-color: $gold;
        transform: rotate(90deg);
        transform-origin: bottom right;
        transition: 1s;
      }
    }
  }
  h1{
    margin: 0;
    font-family: 'Cinzel', serif !important;
  }
  &.animate{
    .first{
      &::before{
        height: 50%;
        left: 0;
      }
      &::after{
        width: 15%;
        left: 0;
        transform: rotate(0);
      }
    }
    .last{
      &::before{
        height: 50%;
        right: 0;
      }
      &::after{
        width: 15%;
        right: 0;
        transform: rotate(0);
      }
    }
  }
}
