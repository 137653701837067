.containerGallery{
  background-image: url('../images/gallery.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 2em;
  .contentGallery{
      height: auto;
    .titleAnimate{
      margin-bottom: 1em;
    }
    .containerImages{
        width: 100%;
        height: 75vh;
        display: grid;
        grid-template-columns: 20% 20% 20% 20% 20%;
        grid-auto-rows: 50%;
        grid-auto-flow: row;
        .imageInst{
            grid-column: span 1;
            grid-row: span 1;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            position: relative;
            .maskImg{
                height: 100%;
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background-color: #000;
                opacity: .7;
            }
            &:hover{
                .maskImg{
                    display: none;
                }
            }
        }
        @include mq($mobile){
            height: auto;
            grid-template-columns: 50% 50%;
            grid-auto-rows: 200px;
            grid-auto-flow: row;
            margin-bottom: 2em;
        }
    }
  }
}
