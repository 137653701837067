.containerBoobles{
  background-image: url('../images/boobles.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  .contentBoobles{
    height: 100%;
    width: 100%;
    position: relative;
    .titleBoobles{
      position: relative;
      height: 20%;
      display: flex;
      justify-content: center;
      align-items: center;
      .maskTitleBoo{
        position: absolute;
        height: 100%;
        width: 100%;
        opacity: .4;
        z-index: 1;
      }
      .titleSection{
        position: relative;
        z-index: 2;
        @include mq($mobile){
          font-size: 16px;
        }
      }
    }
    .containerAnimBoobles{
      height: 80%;
      position: relative;
      .booble{
        border-radius: 50%;
        border: 2px solid #fff;
        position: absolute;
        top: 20%;
        left: 30%;
        height: 7em;
        width: 7em;
        display: flex;
        justify-content: center;
        align-items: center;
        animation: destello-efecto-01 5s infinite;
        -webkit-animation: destello-efecto-01 2s infinite;

        @keyframes destello-efecto-01 {
          0%, 50%, 100% {opacity: 1;}
          25%, 75% {opacity: 0.9;-webkit-transform: scale3d(.95, .95, .95);transform: scale3d(.95, .95, .95);}
        }

        .contentBooble{
          border-radius: 50%;
          background-color: #fff;
          height: 4em;
          width: 4em;
          transition: .5s;
          display: flex;
          justify-content: center;
          align-items: center;
          p{
            color: #fff;
            font-weight: bold;
            opacity: 0;
            transition: .7s;
          }
          &:hover{
            height: 7em;
            width: 7em;
            background-color: transparent;
            p{
              opacity: 1;
            }
          }
        }
      }
    }
  }
  .boobleMoving{
    .simpleBooble{
      height: 1em;
      width: 1em;
      border-radius: 50%;
      position: absolute;
      z-index: 2;
      bottom: 0;
      left: 5%;
      border: 2px solid #f1f1f1;
      opacity: .7;
      animation: moveBooble 2.5s infinite;
      -webkit-animation: moveBooble 2.5s infinite;
      &:nth-child(2){
        left: 30%;
        animation: moveBooble 3s infinite;
        -webkit-animation: moveBooble 3s infinite;
      }
      &:nth-child(3){
        left: 50%;
        animation: moveBooble 4s infinite;
        -webkit-animation: moveBooble 4s infinite;
      }
      &:nth-child(4){
        left: 70%;
        animation: moveBooble 2s infinite;
        -webkit-animation: moveBooble 2s infinite;
      }
      &:nth-child(5){
        left: 90%;
        animation: moveBooble 3.5s infinite;
        -webkit-animation: moveBooble 3.5s infinite;
      }
      &:nth-child(6){
        left: 15%;
        animation: moveBooble 3.8s infinite;
        -webkit-animation: moveBooble 3.8s infinite;
      }
      @keyframes moveBooble {
        0% {bottom: -10%}
        100% {bottom: 110%}
      }
    }
  }
}
