@import "variables.scss";

.containerCover{
    background-color: $black;
    .coverContent{
        height: 100%;
        width: 100vw;
        position:relative;
        overflow:hidden;
        .videoFdm{
            height:100%;
        	width:100%;
        	position:absolute;
        	top:0;
        	left:0;
        }
    }
}
