.contentBeers{
  padding: 0 1em;
  @include mq($mobile){
    padding: 0;
  }
  .descriptionBeerContainer{
    position: absolute;
    top: 5%;
    left: 2%;
    width: 20%;
    padding: .5em 1em;
    @include mq($mobile){
      width: 80%;
      left: 50%;
      transform: translateX(-50%);
      .titleDescriptionBeer{
        font-size: 1em;
      }
    }
    .titleDescriptionBeer{
      margin-top: 0;
      margin-bottom: 0;
      text-transform: uppercase;
    }
    .descriptionBeer{
      text-align: left;
      font-size: .8em;
      span{
        font-weight: bold;
      }
    }
    .first{
      &::before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        background-color: $gold;
        height: 2em;
        width: 2px;
      }
      &::after{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        background-color: $gold;
        height: 2px;
        width: 3em;
      }
    }
    .last{
      &::before{
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        background-color: $gold;
        height: 2em;
        width: 2px;
      }
      &::after{
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        background-color: $gold;
        height: 2px;
        width: 3em;
      }
    }
  }
  .sliderBeers{
      margin-top: 4em;
    height: 80%;
    width: 100%;
    position: relative;
    .slideBeer{
      height: 70%;
      width:fit-content;
      position: absolute;
      display: flex !important;
      justify-content: center;
      align-items: flex-start;
      transition: 1s;
      &[dataB='0']{
          top: 30%;
          left: 20%;
          z-index: 3;
          &.pinta,
          &.stout{
              left: 25%;
          }
          // .descriptionBeer{
          //     transition-delay: 1s;
          //     display: block;
          // }
      }
      &[dataB='1']{
          top: 30%;
          left: 60%;
          z-index: 3;
      }
      &[dataB='2']{
          top: 20%;
          left: 70%;
          z-index: 2;
          height: 60%;
          &.copa{
            left: 75%;
          }
      }
      &[dataB='3']{
          top: 20%;
          left: 15%;
          z-index: 2;
          height: 60%;
          &.tarro{
            left: 10%;
          }
      }
      .descriptionBeer{
          width: 40%;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          display: none;
      }
      .imageBeer{
        width: fit-content;
        height:100%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        img{
          height: 100%;
          &.copa{
            height: 80%;
          }
          &.tarro{
            height: 70%;
          }
        }
      }
    }
    @include mq($mobile){
      margin-top: 2em;
      .slideBeer{
        height: 50%;
        justify-content: center;
        &[dataB='0']{
          left: 10%;
          &.stout,
          &.pinta{
            left: 10%;
          }
          &.tarro{
            left: 2%;
          }
        }
        &[dataB='1']{
          left: 60%;
        }
        &[dataB='2']{
          left: 55%;
          height: 40%;
          opacity: 0;
        }
        &[dataB='3']{
          left: 0%;
          height: 40%;
          opacity: 0;
        }
        .imageBeer{
          width: auto;
          img{
            height: 90%;
            width: auto;
          }
        }
      }
    }
    @include mq(450px){
      .slideBeer{
        .imageBeer{
          img{
            height: 70% !important;
          }
        }
      }
    }
  }
  .moveSlider{
    border: none;
    background-color: transparent;
    color: $brown;
    font-weight: bold;
    position: relative;
    top: 20%;
    &:focus{
      outline: none;
    }
    @include mq($mobile){
      top: 30%;
    }
  }
}


///Keyframes animations

// @keyframes move3 {
//     0%{ transform: translate3d(0%, 0%, 0px) }
//     50%{ transform: translate3d(50%, 10%, 1px) }
//     50%{ transform: translate3d(100%, 0%, 0px) }
// }
